import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import ProtectedRoute from 'components/routing/ProtectedRoutes';
import PublicRoute from 'components/routing/PublicRoutes';
import AdminLayout from 'layouts/admin';
import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SignInCentered from 'views/auth/signIn';
import './assets/css/App.css';
import initialTheme from './theme/theme';

export default function Main() {
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        {/* Public routes */}
        <Route element={<PublicRoute />}>
          <Route path="auth/*" element={<SignInCentered />} />
        </Route>

        {/* Protected routes */}
        <Route element={<ProtectedRoute />}>
          <Route
            path="/admin/*"
            element={
              <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
            }
          >
            <Route />
          </Route>
        </Route>
        <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
      </Routes>
    </ChakraProvider>
  );
}
