import React, { useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  GridItem,
  Input,
  useColorModeValue,
  FormLabel,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import MapView from 'components/detailMapData/MapView';

const GoogleMapAddress = ({
  coordinates,
  setCoordinates,
  initialAddress,
  onAddressChange,
  editmerchantId,
}) => {
  const textColor = useColorModeValue('navy.700', 'white');
  const [suggestions, setSuggestions] = useState([]);
  const [address, setAddress] = useState(initialAddress);
  const handleAddressChange = async (event) => {
    const input = event.target.value;
    setAddress(input);
    onAddressChange(input);
    if (input) {
      try {
        const response = await fetch(
          `https://cors.stage.eugeniuses.com/https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSuggestions(data.predictions);
      } catch (error) {
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = async (placeId) => {
    try {
      const response = await fetch(
        `https://cors.stage.eugeniuses.com/https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const location = data.result.geometry.location;

      setCoordinates({
        lat: location.lat,
        lng: location.lng,
      });
      setAddress(data.result.formatted_address);
      onAddressChange(data.result.formatted_address);
      setSuggestions([]);
    } catch (error) {
      // console.error('Error fetching place details:', error);
    }
  };

  useEffect(() => {
    setAddress(initialAddress);
  }, [initialAddress]);

  return (
    <>
      <Box position="relative">
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          Coordinates
        </FormLabel>
        <Input
          variant="auth"
          fontSize="sm"
          mb="2px"
          fontWeight="500"
          size="lg"
          value={address}
          onChange={handleAddressChange}
          placeholder="Enter an address"
        />
        {suggestions.length > 0 && (
          <Box
            border="1px"
            position="absolute"
            zIndex="1"
            bg="white"
            borderColor="gray.200"
            borderRadius="md"
            maxH="200px"
            overflowY="auto"
          >
            <List spacing={1}>
              {suggestions?.map((suggestion) => (
                <ListItem
                  key={suggestion.place_id}
                  onClick={() => handleSuggestionClick(suggestion.place_id)}
                  cursor="pointer"
                  padding="8px"
                  _hover={{ backgroundColor: 'gray.100' }}
                >
                  {suggestion.description}
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
      <GridItem colSpan={{ base: 1, lg: 3 }}>
        <MapView
          // coordinates={[coordinates.lat, coordinates.lng]}
          coordinates={
            editmerchantId
              ? [coordinates.lat, coordinates.lng]
              : [coordinates.lng, coordinates.lat]
          }
          isChange={true}
          setCoordinates={(event) => {
            setCoordinates(event);
            setAddress('');
            setSuggestions([]);
          }}
        />
      </GridItem>
    </>
  );
};

GoogleMapAddress.propTypes = {
  coordinates: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  setCoordinates: PropTypes.func.isRequired,
  initialAddress: PropTypes.string.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  editmerchantId: PropTypes.string,
};

export default GoogleMapAddress;
