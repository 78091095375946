export const userData = [
  { id: 1, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 2, title: 'Jane Smith', tech: ['windows'] },
  { id: 3, title: 'Michael Johnson', tech: ['apple'] },
  { id: 4, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 5, title: 'Jane Smith', tech: ['windows'] },
  { id: 6, title: 'Michael Johnson', tech: ['apple'] },
  { id: 7, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 8, title: 'Jane Smith', tech: ['windows'] },
  { id: 9, title: 'Michael Johnson', tech: ['apple'] },
  { id: 10, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 11, title: 'Jane Smith', tech: ['windows'] },
  { id: 12, title: 'Michael Johnson', tech: ['apple'] },
  { id: 13, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 14, title: 'Jane Smith', tech: ['windows'] },
  { id: 15, title: 'Michael Johnson', tech: ['apple'] },
  { id: 16, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 17, title: 'Jane Smith', tech: ['windows'] },
  { id: 18, title: 'Michael Johnson', tech: ['apple'] },
  { id: 19, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 20, title: 'Jane Smith', tech: ['windows'] },
  { id: 21, title: 'Michael Johnson', tech: ['apple'] },
  { id: 22, title: 'John Doe', tech: ['apple', 'android'] },
  { id: 23, title: 'Jane Smith', tech: ['windows'] },
  { id: 24, title: 'Michael Johnson', tech: ['apple'] },
];

export const rentalStatusMap = {
  0: 'Can Rental',
  1: 'On Rent',
  2: 'Revoked',
  3: 'Returned',
  4: 'Trouble Order',
  5: 'Customer Purchase',
  6: 'Suspend Billing',
};
