import React from 'react';
import { Box, Card, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageLoader from '../../../components/wholePageLoader/PageLoader';
import { ViewUserData } from '../../../reactRedux/thunks/userThunk/userThunk';

const UserDetails = () => {
  const { userDetailId } = useParams();
  const dispatch = useDispatch();
  const { viewUserDetails, isViewLoading } = useSelector(
    (state) => state.UserSlice,
  );

  useEffect(() => {
    if (userDetailId) {
      dispatch(ViewUserData({ id: userDetailId }));
    }
  }, [userDetailId, dispatch]);

  return (
    <>
      {isViewLoading ? (
        <PageLoader />
      ) : (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <Flex direction="column" align="center" justify="center">
            {viewUserDetails ? (
              <>
                <Card
                  w="100%"
                  boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
                  borderRadius="20px"
                  paddingInline="40px"
                  paddingBlock="40px"
                  overflowX={{ sm: 'scroll', lg: 'hidden' }}
                >
                  <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    spacing="24px"
                  >
                    <Flex
                      align="center"
                      gap="10px"
                      paddingBlock={{ base: '0', md: '10px' }}
                      borderRight={{ base: 'none', lg: '1px solid #ccc' }}
                    >
                      <Text fontWeight="normal">First Name:</Text>
                      <Text fontWeight="semibold">
                        {viewUserDetails.firstName}
                      </Text>
                    </Flex>
                    <Flex
                      align="center"
                      gap="10px"
                      paddingBlock={{ base: '0', md: '10px' }}
                      borderRight={{ base: 'none', lg: '1px solid #ccc' }}
                    >
                      <Text fontWeight="normal">Last Name:</Text>
                      <Text fontWeight="semibold">
                        {viewUserDetails.lastName}
                      </Text>
                    </Flex>
                    {viewUserDetails.email && (
                      <Flex
                        align="center"
                        gap="10px"
                        paddingBlock={{ base: '0', md: '10px' }}
                      >
                        <Text fontWeight="normal">Email:</Text>
                        <Text fontWeight="semibold">
                          {viewUserDetails.email}
                        </Text>
                      </Flex>
                    )}
                    <Flex
                      align="center"
                      gap="10px"
                      paddingBlock={{ base: '0', md: '10px' }}
                    >
                      <Text fontWeight="normal">Phone:</Text>
                      <Text fontWeight="semibold">{viewUserDetails.phone}</Text>
                    </Flex>
                  </SimpleGrid>
                </Card>
              </>
            ) : (
              <Text>No user details found.</Text>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};

export default UserDetails;
