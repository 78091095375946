export const LOGIN = '/admin/login';
export const USERLIST = '/user/list';
export const USER = '/user';
export const MERCHANTLIST = '/merchant/list';
export const MERCHANT = '/merchant';
export const STATIONLIST = '/station/list';
export const STATION = '/station';
export const POWERBANKLIST = '/powerbank/list';
export const POWERBANK = '/powerbank';
export const ORDERLIST = '/order/list';
export const ORDER = '/order';
