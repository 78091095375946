import { createSlice } from '@reduxjs/toolkit';

const StationSlice = createSlice({
  name: 'StationSlice',
  initialState: {
    isLoading: false,
    isViewLoading: false,
    stationListData: [],
    isError: null,
    viewStationDetails: null,
  },
  reducers: {
    /////// Station list start //////

    requestStationList: (state) => {
      return { ...state, isLoading: true };
    },
    responseStationList: (state, action) => {
      return {
        ...state,
        isLoading: false,
        stationListData: action?.payload?.data,
      };
    },
    failureStationList: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action?.payload,
      };
    },

    /////// Station Add-Edit //////
    requestStationAdd: (state) => {
      return { ...state, isLoading: true };
    },
    responseStationAdd: (state) => {
      return { ...state, isLoading: false };
    },
    failureStationAdd: (state, action) => {
      return { ...state, isLoading: false, isError: action?.payload };
    },

    ////////// Station View /////////
    requestStationView: (state) => {
      return { ...state, isViewLoading: true };
    },
    responseStationView: (state, action) => {
      return {
        ...state,
        isViewLoading: false,
        viewStationDetails: action.payload,
      };
    },
    failureStationView: (state, action) => {
      return { ...state, isViewLoading: false, isError: action?.payload };
    },

    ////// Station Delete ////
    requestStationDelete: (state) => {
      return { ...state, isLoading: true };
    },
    responseStationDelete: (state) => {
      return { ...state, isLoading: false };
    },
    failureStationDelete: (state, action) => {
      return { ...state, isLoading: false, isError: action?.payload };
    },
  },
});

export const {
  failureStationList,
  responseStationList,
  requestStationList,
  failureStationAdd,
  requestStationAdd,
  responseStationAdd,
  failureStationView,
  requestStationView,
  responseStationView,
  failureStationDelete,
  requestStationDelete,
  responseStationDelete,
} = StationSlice.actions;
export default StationSlice.reducer;
