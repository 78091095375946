import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonPagination from 'components/commonPagination/CommonPagination';
import CommonTable from 'components/commonTable/CommonTable';
import DeleteModal from 'components/deleteModalPopUp/DeleteModal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { PiEye, PiPencil, PiTrash } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  DeletePowerBankData,
  ListPowerBankData,
} from 'reactRedux/thunks/powerBankThunk/powerBankThunk';

const PowerBankList = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const powerBankDeleteRef = useRef(null);
  // const [search, setSearch] = useState("")
  const navigate = useNavigate();
  const { isLoading, powerBankListData } = useSelector(
    (state) => state.PowerBankSlice,
  );

  const getPowerbankList = useCallback(() => {
    dispatch(
      ListPowerBankData({
        page: page,
        limit: 10,
        // search : search
      }),
    );
  }, [dispatch, page]);

  useEffect(() => {
    getPowerbankList();
  }, [getPowerbankList]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.powerbankId, {
      id: 'powerbankId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Powerbank Id
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.stationId, {
      id: 'stationId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="80px"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Station Id
        </Text>
      ),
      cell: (info) => {
        const stationId = info.getValue();
        const stationIdValue =
          stationId && typeof stationId === 'object'
            ? stationId.stationId
            : stationId;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="600">
              {stationIdValue || 'N/A'}
            </Text>
          </Flex>
        );
      },
      // cell: (info) => (
      //   <Flex align="center">
      //     <Text color={textColor} fontSize="sm" fontWeight="600">
      //       {info.getValue() ? info.getValue() : 'N/A'}
      //     </Text>
      //   </Flex>
      // ),
    }),
    columnHelper.accessor((row) => row?.actions, {
      id: 'actions',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="50px"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          ACTION
        </Text>
      ),
      cell: (row) => (
        <Flex align="center">
          <Link
            to={`/admin/powerbanks/edit/${row?.row?.original?.powerbankId}`}
            className="btn btn-light rounded-3 p-1"
          >
            <PiPencil size={20} title="Edit" style={{ marginRight: '20px' }} />
          </Link>
          <Link
            to={`/admin/powerbanks/details/${row?.row?.original?.powerbankId}`}
            className="btn btn-primary rounded-2 p-1"
          >
            <PiEye
              size={20}
              title="View"
              className="text-white"
              style={{ marginRight: '20px' }}
            />
          </Link>
          <div className="btn btn-danger rounded-3 p-1">
            <PiTrash
              size={20}
              className="text-white"
              onClick={() => {
                // setShow(true);
                onOpen();
                powerBankDeleteRef.current = row?.row?.original?.powerbankId;
              }}
              title="Delete"
            />
          </div>
        </Flex>
      ),
    }),
  ];

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex justify="flex-end" mb={4} gap="20px">
          {/* <CommonSearch search={search} setSearch={setSearch} setPage={setPage} /> */}
          <Button
            bg="reviveOrange"
            color="primary"
            borderRadius="12px"
            _hover={{ bg: 'reviveOrange', opacity: 0.8 }}
            onClick={() => navigate('/admin/powerbanks/add')}
          >
            <Icon
              as={FaPlus}
              width="15px"
              height="15px"
              marginRight="5px"
              color="inherit"
            />
            ADD Powerbank
          </Button>
        </Flex>
        <CommonTable
          columns={columns}
          data={powerBankListData?.list}
          isLoading={isLoading}
          keyValue="id"
          rowclick={['actions', 'status']}
          excludes={['actions', 'status']}
        />
        {powerBankListData?.total > 10 && (
          <CommonPagination
            totalCount={powerBankListData?.total}
            activePage={page}
            pageCount={Math.ceil(
              powerBankListData?.total / powerBankListData?.limit,
            )}
            onPageChange={(e) => setPage(e)}
          />
        )}

        <DeleteModal
          isOpen={isOpen}
          onClose={onClose}
          title="Are you sure you want to delete this powerbank?"
          SolidBTNText="Delete"
          onDelete={() => {
            dispatch(
              DeletePowerBankData(powerBankDeleteRef.current, {}, () => {
                getPowerbankList();
                onClose();
              }),
            );
          }}
          loader={isLoading}
        />
      </Box>
    </>
  );
};

export default PowerBankList;
