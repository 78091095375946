import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import CommonInput from 'components/commonInput/CommonInput';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useColorModeVariables } from '../../../components/colorModeVarialbels/ColorModeValues';
import {
  AddMerchantData,
  EditMerchantData,
  ViewMerchantData,
} from '../../../reactRedux/thunks/merchnatThunk/merchantThunk';
import { merchantAddEditValidation } from '../../../utils/CommonValidation';
import CommonSubmitBtn from 'components/commonInput/CommonSubmitBtn';
import { FaPlus } from 'react-icons/fa';
import StationAllocationListModal from './StationAllocationListModal';
import { CloseIcon } from '@chakra-ui/icons';
import PageLoader from 'components/wholePageLoader/PageLoader';
import GoogleMapAddress from './GoogleMapAddress';

const MerchantAddEdit = () => {
  const { editmerchantId } = useParams();
  const [stationIds, setStationIds] = useState([]);
  const [temStationIds, setTempStationIds] = useState([]);
  const [coordinates, setCoordinates] = useState({
    lat: 23.01052,
    lng: 72.51129,
  });
  // eslint-disable-next-line no-unused-vars
  const [initialAddress, setInitialAddress] = useState('');
  const [address, setAddress] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { textColor, brandStars } = useColorModeVariables();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, viewMerchantDetails, isViewLoading } = useSelector(
    (state) => state.MerchantSlice,
  );
  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(merchantAddEditValidation),
  });

  const fetchAddress = async (coordinates) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates[0]},${coordinates[1]}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        setInitialAddress(data.results[0].formatted_address); // Set the initial address
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching address:', error);
    }
  };

  const onSubmit = (data) => {
    const { ...payload } = data;
    const editPayload = {
      ...payload,
      stationIds: stationIds,
      overtimeFees: Number(data?.overtimeFees),
      address: address, // Include the address from state
      coordinates: [coordinates.lat, coordinates.lng],
    };

    if (editmerchantId) {
      dispatch(
        EditMerchantData(editmerchantId, editPayload, () => {
          navigate('/admin/merchants');
        }),
      );
    } else {
      dispatch(
        AddMerchantData(
          {
            ...data,
            stationIds: stationIds,
            overtimeFees: Number(data?.overtimeFees),
            address: address,
            coordinates: [coordinates.lat, coordinates.lng],
            priceMinute: Number(data?.priceMinute),
          },
          () => {
            navigate('/admin/merchants');
          },
        ),
      );
    }
  };

  useEffect(() => {
    if (editmerchantId) {
      dispatch(ViewMerchantData({ id: editmerchantId }));
    }
  }, [dispatch, editmerchantId]);

  useEffect(() => {
    if (viewMerchantDetails && editmerchantId) {
      setValue('daysLimit', viewMerchantDetails?.daysLimit);
      setValue('deposit', viewMerchantDetails?.deposit);
      setValue('freeDuration', viewMerchantDetails?.freeDuration);
      setValue('name', viewMerchantDetails?.name);
      setValue('currency', viewMerchantDetails?.currency);
      setValue('locationId', viewMerchantDetails?.locationId);
      setValue('overtimeFees', viewMerchantDetails?.overtimeFees);
      setValue('priceMinute', viewMerchantDetails?.priceMinute);
      setValue('price', viewMerchantDetails?.price);
      const stationIds =
        viewMerchantDetails?.stations?.map((station) => station.stationId) ||
        [];
      setStationIds(stationIds);
      setTempStationIds(stationIds);
      setAddress(viewMerchantDetails?.address);
      if (
        viewMerchantDetails.coordinates &&
        viewMerchantDetails.coordinates.length === 2
      ) {
        setCoordinates({
          lat: viewMerchantDetails.coordinates[0],
          lng: viewMerchantDetails.coordinates[1],
        });
        fetchAddress(viewMerchantDetails.coordinates);
      }
    }
  }, [viewMerchantDetails, editmerchantId, setValue]);

  const handleRemoveStationId = (stationIdToRemove) => {
    const updatedStationIds = stationIds.filter(
      (stationId) => stationId !== stationIdToRemove,
    );
    setStationIds(updatedStationIds);
    setTempStationIds(updatedStationIds);
  };

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex direction="column" align="center" justify="center">
          <Card
            w="100%"
            boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
            borderRadius="20px"
            paddingInline="40px"
            paddingBlock="50px"
            overflowX={{ sm: 'scroll', lg: 'hidden' }}
          >
            {isViewLoading ? (
              <PageLoader />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormControl>
                  <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    spacing="24px"
                  >
                    {/* Merchant Name */}
                    <CommonInput
                      type="text"
                      name="name"
                      labelText="Merchant Name"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="Merchant Name"
                      register={register('name')}
                      errors={errors?.name}
                    />

                    {/* Free Duration */}
                    <CommonInput
                      type="number"
                      name="freeDuration"
                      labelText="Free Duration"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="Free Duration"
                      register={register('freeDuration')}
                      errors={errors?.freeDuration}
                    />

                    {/* currancy */}
                    <CommonInput
                      type="text"
                      name="currency"
                      labelText="Currency"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="Currency"
                      register={register('currency')}
                      errors={errors?.currency}
                    />

                    {/* location Id */}
                    <CommonInput
                      type="text"
                      name="locationId"
                      labelText="Location Id"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="Location Id"
                      register={register('locationId')}
                      errors={errors?.locationId}
                    />

                    {/* Price */}
                    <CommonInput
                      type="number"
                      name="price"
                      labelText="Price"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="Price"
                      register={register('price')}
                      errors={errors?.price}
                    />

                    {/* Deposit */}
                    <CommonInput
                      type="number"
                      name="deposit"
                      labelText="Deposit"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="Deposit"
                      register={register('deposit')}
                      errors={errors?.deposit}
                    />

                    {/* over time fees */}
                    <CommonInput
                      type="number"
                      name="overtimeFees"
                      labelText="Over Time Fees"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="Over Time Fees"
                      register={register('overtimeFees')}
                      errors={errors?.overtimeFees}
                    />

                    {/* priceMinute */}
                    <CommonInput
                      type="number"
                      name="priceMinute"
                      labelText="Price Minutes"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="Price Minute"
                      register={register('priceMinute')}
                      errors={errors?.priceMinute}
                    />

                    {/* Days Limit */}
                    <CommonInput
                      type="number"
                      name="daysLimit"
                      labelText="Days Limit"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="Days Limit"
                      register={register('daysLimit')}
                      errors={errors?.daysLimit}
                    />

                    <GoogleMapAddress
                      coordinates={coordinates}
                      setCoordinates={setCoordinates}
                      initialAddress={address}
                      onAddressChange={handleAddressChange}
                      editmerchantId={editmerchantId}
                    />
                  </SimpleGrid>

                  {stationIds?.length > 0 && (
                    <Flex align="center" gap={3} mt={2} marginTop={'50px'}>
                      <FormLabel flex={'none'} margin={'0px'} color={textColor}>
                        Stations
                      </FormLabel>
                      {stationIds?.map((stationId) => (
                        <Flex key={stationId} gap={1} align="center">
                          <Input
                            value={stationId}
                            readOnly
                            variant="auth"
                            fontSize="sm"
                            mb="2px"
                            fontWeight="500"
                            size="lg"
                          />
                          <IconButton
                            aria-label="Remove Merchant"
                            icon={<CloseIcon />}
                            size="sm"
                            onClick={() => handleRemoveStationId(stationId)}
                          />
                        </Flex>
                      ))}
                    </Flex>
                  )}

                  <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    spacing="24px"
                  >
                    <Button
                      type="button"
                      bg="reviveOrange"
                      mt="50px"
                      h="50"
                      color="primary"
                      borderRadius="12px"
                      _hover={{ bg: 'reviveOrange', opacity: 0.8 }}
                      onClick={onOpen}
                    >
                      <Icon
                        as={FaPlus}
                        width="15px"
                        height="15px"
                        borderRadius="12px"
                        marginRight="5px"
                        color="inherit"
                      />
                      Add Station
                    </Button>
                  </SimpleGrid>

                  <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    spacing="24px"
                  >
                    <CommonSubmitBtn BTNtext="Submit" isLoading={isLoading} />
                  </SimpleGrid>

                  <StationAllocationListModal
                    isOpen={isOpen}
                    onClose={onClose}
                    stationIds={stationIds}
                    setStationIds={setStationIds}
                    editmerchantId={editmerchantId}
                    temStationIds={temStationIds}
                    setTempStationIds={setTempStationIds}
                  />
                </FormControl>
              </form>
            )}
          </Card>
        </Flex>
      </Box>
    </>
  );
};

export default MerchantAddEdit;
