import React from 'react';
import { Button, IconButton, Flex } from '@chakra-ui/react';
import { PiCaretLeft, PiCaretRight } from 'react-icons/pi';
import PropTypes from 'prop-types';

const CommonPagination = ({ onPageChange, pageCount, activePage }) => {
  const handlePageChange = (page) => {
    if (page >= 1 && page <= pageCount) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= pageCount; i++) {
      pages.push(
        <Button
          key={i}
          onClick={() => handlePageChange(i)}
          isActive={i === activePage}
          size="sm"
          variant={i === activePage ? 'solid' : 'outline'}
          mx={1}
        >
          {i}
        </Button>,
      );
    }
    return pages;
  };

  return (
    <Flex alignItems="center" justifyContent="center" mt={4}>
      <IconButton
        icon={<PiCaretLeft />}
        onClick={() => handlePageChange(activePage - 1)}
        isDisabled={activePage === 1}
        aria-label="Previous Page"
        size="sm"
        mx={1}
      />
      {renderPageNumbers()}
      <IconButton
        icon={<PiCaretRight />}
        onClick={() => handlePageChange(activePage + 1)}
        isDisabled={activePage === pageCount}
        aria-label="Next Page"
        size="sm"
        mx={1}
      />
    </Flex>
  );
};

CommonPagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
};

export default CommonPagination;
