import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const PublicRoute = () => {
  const { loginToken } = useSelector((state) => state.authSlice);
  return loginToken ? <Navigate to="/admin/dashboard" replace /> : <Outlet />;
};

export default PublicRoute;
