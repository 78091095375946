import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  Icon,
  IconButton,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import CommonInput from 'components/commonInput/CommonInput';
import React, { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AddPowerBankData,
  EditPowerBankData,
  ViewPowerBankData,
} from 'reactRedux/thunks/powerBankThunk/powerBankThunk';
import { useColorModeVariables } from '../../../components/colorModeVarialbels/ColorModeValues';
import CommonSubmitBtn from '../../../components/commonInput/CommonSubmitBtn';
import { powerbankIdAddEditValidation } from '../../../utils/CommonValidation';
import { responsePowerbankView } from 'reactRedux/slices/powerBankSlice/powerBankSlice';
import { FaPlus } from 'react-icons/fa';
import { CloseIcon } from '@chakra-ui/icons';
import StationAllocationModalList from './StationAllocationModalList';
import PageLoader from 'components/wholePageLoader/PageLoader';

const PowerBankAddEdit = () => {
  const { editpowerbanksId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedMerchantName, setSelectedMerchantName] = useState('');
  const { textColor, brandStars } = useColorModeVariables();
  // const [coordinates, setCoordinates] = useState({ lat: 23.305936, lng: 72.509766 })
  const [selectedMerchants, setSelectedMerchants] = useState('');
  const [isRemove, setIsRemove] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [initialAddress, setInitialAddress] = useState('')
  const { isLoading, viewPowerbankDetails, isViewLoading } = useSelector(
    (state) => state.PowerBankSlice,
  );
  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(powerbankIdAddEditValidation),
  });

  const onSubmit = (data) => {
    const { powerbankId, ...restData } = data;
    const transformedData = {
      ...restData,
      stationId: selectedMerchants,
      // coordinates: [coordinates.lng, coordinates.lat],
    };
    if (editpowerbanksId) {
      dispatch(
        EditPowerBankData(editpowerbanksId, transformedData, () => {
          navigate('/admin/powerbanks');
        }),
      );
    } else {
      dispatch(
        AddPowerBankData({ ...transformedData, powerbankId }, () => {
          navigate('/admin/powerbanks');
        }),
      );
    }
  };

  useEffect(() => {
    if (editpowerbanksId) {
      dispatch(ViewPowerBankData({ id: editpowerbanksId }));
    }
  }, [dispatch, editpowerbanksId]);

  useEffect(() => {
    if (viewPowerbankDetails && editpowerbanksId) {
      setValue('powerbankId', viewPowerbankDetails?.powerbankId);
      setValue('stationId', viewPowerbankDetails?.stationId);
      setSelectedMerchants(viewPowerbankDetails?.stationId || '');
    }
  }, [viewPowerbankDetails, editpowerbanksId, setValue]);

  const resetPowerbankDetails = useCallback(() => {
    dispatch(responsePowerbankView({ viewPowerbankDetails: null }));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      resetPowerbankDetails();
    };
  }, [resetPowerbankDetails]);

  ///// new /////
  const handleRemoveMerchant = () => {
    setSelectedMerchants('');
    setIsRemove(true);
  };

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex direction="column" align="center" justify="center">
          <Card
            w="100%"
            boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
            borderRadius="20px"
            paddingInline="40px"
            paddingBlock="50px"
            overflowX={{ sm: 'scroll', lg: 'hidden' }}
          >
            {isViewLoading ? (
              <PageLoader />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <FormControl>
                  <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    spacing="24px"
                  >
                    {/* Powerbank ID */}
                    <CommonInput
                      type="text"
                      name="powerbankId"
                      labelText="Powerbank Id"
                      textColor={textColor}
                      brandStars={brandStars}
                      placeholder="Powerbank Id"
                      register={register('powerbankId')}
                      errors={errors?.powerbankId}
                    />

                    {/* station ID */}
                    {!selectedMerchants ? (
                      <Button
                        type="button"
                        bg="reviveOrange"
                        mt="30px"
                        h="50"
                        color="primary"
                        borderRadius="12px"
                        _hover={{ bg: 'reviveOrange', opacity: 0.8 }}
                        onClick={onOpen}
                      >
                        <Icon
                          as={FaPlus}
                          width="15px"
                          height="15px"
                          borderRadius="12px"
                          marginRight="5px"
                          color="inherit"
                        />
                        Add Station
                      </Button>
                    ) : (
                      <>
                        <CommonInput
                          type="text"
                          name="stationId"
                          labelText="Station Id"
                          textColor={textColor}
                          brandStars={brandStars}
                          placeholder="Station Id"
                          value={
                            selectedMerchantName
                              ? selectedMerchantName
                              : selectedMerchants
                          }
                          readOnly
                          errors={errors?.stationId}
                        />
                        <Flex align="center" mt={2} marginTop={'30px'}>
                          <IconButton
                            aria-label="Remove Merchant"
                            icon={<CloseIcon />}
                            size="sm"
                            ms={0}
                            onClick={handleRemoveMerchant}
                          />
                        </Flex>
                      </>
                    )}
                  </SimpleGrid>

                  <SimpleGrid
                    columns={{ base: 1, md: 2, lg: 3 }}
                    spacing="24px"
                  >
                    <CommonSubmitBtn BTNtext="Submit" isLoading={isLoading} />
                  </SimpleGrid>

                  <StationAllocationModalList
                    isOpen={isOpen}
                    onClose={onClose}
                    setSelectedMerchants={setSelectedMerchants}
                    setIsRemove={setIsRemove}
                    isRemove={isRemove}
                    setSelectedMerchantName={setSelectedMerchantName}
                  />
                </FormControl>
              </form>
            )}
          </Card>
        </Flex>
      </Box>
    </>
  );
};

export default PowerBankAddEdit;
