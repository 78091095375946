import React from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonPagination from 'components/commonPagination/CommonPagination';
import CommonTable from 'components/commonTable/CommonTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ListStationData } from 'reactRedux/thunks/stationThunk/stationThunk';

const StationAllocationModalList = ({
  isOpen,
  onClose,
  setSelectedMerchants,
  isRemove,
  setIsRemove,
  setSelectedMerchantName,
}) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [page, setPage] = useState(1);
  const [selectedMerchantId, setSelectedMerchantId] = useState('');
  const dispatch = useDispatch();
  // const { isLoading, stationListData } = useSelector(
  //   (state) => state.MerchantSlice,
  // );
  const { isLoading, stationListData } = useSelector(
    (state) => state.StationSlice,
  );

  useEffect(() => {
    dispatch(ListStationData({ page: page, limit: 10 }));
  }, [page, dispatch]);

  const handleRadioChange = (id) => {
    setSelectedMerchantId(id);
    // const selectedMerchant = stationListData?.list.find(
    //   (merchant) => merchant.merchantId === id,
    // );
    // // Set the selected merchant's name
    // if (selectedMerchant) {
    //   setSelectedMerchantName(selectedMerchant.name);
    // }
  };

  useEffect(() => {
    if (isRemove) {
      setSelectedMerchantId('');
      setSelectedMerchantName('');
      setIsRemove(false);
    }
  }, [isRemove, setIsRemove, setSelectedMerchantName]);

  const handleSelectMerchant = () => {
    setSelectedMerchants(selectedMerchantId);
    onClose();
  };

  const onModalCloseIcon = () => {
    setSelectedMerchantId('');
    setIsRemove(false);
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.stationId, {
      id: 'selectMerchant',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="60px"
          whiteSpace="nowrap"
        >
          Select
        </Text>
      ),
      cell: (info) => (
        <Radio
          value={info.getValue()}
          onChange={() => handleRadioChange(info.getValue())}
          isChecked={selectedMerchantId === info.getValue()}
        />
      ),
    }),
    columnHelper.accessor((row) => row?.stationId, {
      id: 'stationId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
        >
          Station Id
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
  ];

  return (
    <Box>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Merchant List</ModalHeader>
          <ModalCloseButton onClick={() => onModalCloseIcon()} />
          <ModalBody>
            <CommonTable
              columns={columns}
              data={stationListData?.list}
              isLoading={isLoading}
              keyValue="id"
              rowclick={['actions', 'status']}
              excludes={['actions', 'status']}
            />
            {stationListData?.total > 10 && (
              <CommonPagination
                totalCount={stationListData?.total}
                activePage={page}
                pageCount={Math.ceil(
                  stationListData?.total / stationListData?.limit,
                )}
                onPageChange={(e) => setPage(e)}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={handleSelectMerchant}
              isDisabled={!selectedMerchantId}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

StationAllocationModalList.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setSelectedMerchants: PropTypes.func.isRequired,
  isRemove: PropTypes.bool.isRequired,
  setIsRemove: PropTypes.func.isRequired,
  setSelectedMerchantName: PropTypes.func.isRequired,
};

export default StationAllocationModalList;
