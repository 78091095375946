export const formatDate = (isoDateString) => {
  if (!isoDateString) return '';

  const date = new Date(isoDateString);
  const day = String(date.getDate()).padStart(2, '0'); // Ensure 2-digit day
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};
