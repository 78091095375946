/* eslint-disable react/prop-types */
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonTable from 'components/commonTable/CommonTable';
import React from 'react';

const PowerBankListDataTable = ({ tableData, isLoading }) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.powerbankId, {
      id: 'powerbankId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Powerbank Id
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
  ];

  return (
    <>
      <Box>
        <CommonTable
          columns={columns}
          data={tableData}
          isLoading={isLoading}
          keyValue="id"
          rowclick={['actions', 'status']}
          excludes={['actions', 'status']}
        />
      </Box>
    </>
  );
};

export default PowerBankListDataTable;
