import { createSlice } from '@reduxjs/toolkit';

const OrderSlice = createSlice({
  name: 'OrderSlice',
  initialState: {
    isLoading: false,
    isViewLoading: false,
    orderListData: [],
    isError: null,
    viewOrderDetails: null,
  },
  reducers: {
    /////// Order list start //////

    requestOrderList: (state) => {
      return { ...state, isLoading: true };
    },
    responseOrderList: (state, action) => {
      return {
        ...state,
        isLoading: false,
        orderListData: action?.payload?.data,
      };
    },
    failureOrderList: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action?.payload,
      };
    },

    /////// Order Add-Edit //////
    // requestOrderAdd: (state) => {
    //   return { ...state, isLoading: true };
    // },
    // responseOrderAdd: (state, action) => {
    //   return { ...state, isLoading: false };
    // },
    // failureOrderAdd: (state, action) => {
    //   return { ...state, isLoading: false, isError: action?.payload };
    // },

    ////////// Order View /////////
    requestOrderView: (state) => {
      return { ...state, isViewLoading: true };
    },
    responseOrderView: (state, action) => {
      return {
        ...state,
        isViewLoading: false,
        viewOrderDetails: action.payload,
      };
    },
    failureOrderView: (state, action) => {
      return { ...state, isViewLoading: false, isError: action?.payload };
    },

    ////// Order Delete ////
    // requestOrderDelete: (state) => {
    //   return { ...state, isLoading: true };
    // },
    // responseOrderDelete: (state, action) => {
    //   return { ...state, isLoading: false };
    // },
    // failureOrderDelete: (state, action) => {
    //   return { ...state, isLoading: false, isError: action?.payload };
    // },
  },
});

export const {
  failureOrderList,
  requestOrderList,
  responseOrderList,
  failureOrderView,
  requestOrderView,
  responseOrderView,
} = OrderSlice.actions;
export default OrderSlice.reducer;
