import React from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonTable from 'components/commonTable/CommonTable';
import { formatDate } from 'utils/CommonFunction';
import PropTypes from 'prop-types';

const StationMerchantDetail = ({ tableData, isLoading }) => {
  const textColor = useColorModeValue('primary', 'white');
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.stationId, {
      id: 'stationId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="80px"
        >
          Station Id
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.slots, {
      id: 'slots',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="50px"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Slots
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            padding={'6px'}
            fontSize="sm"
            fontWeight="600"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.allocatedDate, {
      id: 'allocatedDate',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="80px"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Allocated Date
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {formatDate(info.getValue())}
          </Text>
        </Flex>
      ),
    }),
  ];

  return (
    <>
      <Box pt="4px">
        <CommonTable
          columns={columns}
          data={tableData}
          isLoading={isLoading}
          keyValue="id"
          rowclick={['actions', 'status']}
          excludes={['actions', 'status']}
        />
      </Box>
    </>
  );
};

StationMerchantDetail.propTypes = {
  tableData: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default StationMerchantDetail;
