/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonPagination from 'components/commonPagination/CommonPagination';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ListOrderData } from 'reactRedux/thunks/orderThunk/orderThunk';
import { rentalStatusMap } from 'utils/StaticData';
import CommonTable from '../../../components/commonTable/CommonTable';
import { PiEye } from 'react-icons/pi';

const OrderList = () => {
  const [page, setPage] = useState(1);
  const { state } = useLocation();
  const [paymentStatus, setPaymentStatus] = useState('');
  const [rentalStatus, setRentalStatus] = useState('');
  const [search, setSearch] = useState(
    state?.merchantId ? state?.merchantId : '',
  );
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const dispatch = useDispatch();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { isLoading, orderListData } = useSelector((state) => state.OrderSlice);

  const getOrderList = useCallback(
    (debouncedSearch, status, rental) => {
      dispatch(
        ListOrderData({
          page: page,
          limit: 10,
          merchantId: debouncedSearch,
          paymentStatus: status,
          rentalStatus: rental,
        }),
      );
    },
    [dispatch, page],
  );

  useEffect(() => {
    getOrderList(debouncedSearch, paymentStatus, rentalStatus);
  }, []);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.orderId, {
      id: 'orderId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="40px"
          whiteSpace="nowrap"
        >
          Order Id
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Link
            to={`/admin/orders/details/${info?.row?.original?.orderId}`}
            className="btn btn-primary rounded-2 p-1"
          >
            <Text
              color={textColor}
              marginLeft="10px"
              fontSize="sm"
              fontWeight="600"
            >
              {info.getValue()}
            </Text>
          </Link>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.powerbankId, {
      id: 'powerbankId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
        >
          Powerbank
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.paymentType, {
      id: 'paymentType',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
        >
          Payment Type
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalStatus, {
      id: 'rentalStatus',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
          marginLeft={'20px'}
        >
          Rental Status
        </Text>
      ),
      cell: (info) => {
        const statusValue = info.getValue();
        const statusText = rentalStatusMap[statusValue] || 'N/A';

        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="600"
              marginLeft={'25px'}
              width={`max-content`}
            >
              {statusText}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor((row) => row?.paymentStatus, {
      id: 'paymentStatus',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="70px"
          whiteSpace="nowrap"
          marginLeft={'10px'}
        >
          Payment Status
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={info.getValue() === 1 ? 'green.500' : 'red.500'}
            marginLeft="10px"
            fontSize="sm"
            fontWeight="600"
          >
            {info.getValue() === 1 ? 'Paid' : 'Unpaid'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.rentTime, {
      id: 'rentTime',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
        >
          Rent Time
        </Text>
      ),
      cell: (info) => {
        const rentTime = info.getValue();
        const formattedDateTime = moment(rentTime).format(
          'YYYY-MM-DD HH:mm:ss',
        );
        return (
          <Flex align="center">
            <Text
              color={textColor}
              marginLeft="3px"
              fontSize="sm"
              fontWeight="600"
              width={`max-content`}
            >
              {formattedDateTime}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor((row) => row?.duration, {
      id: 'duration',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
          marginLeft={'10px'}
        >
          Duration
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            marginLeft="10px"
            fontSize="sm"
            fontWeight="600"
            width={'max-content'}
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalMerchantId, {
      id: 'rentalMerchantId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
          margin={'10px'}
        >
          Rental Merchant
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalMerchant, {
      id: 'rentalMerchant',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
        >
          Rental Merchant
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="600"
            width={`max-content`}
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalStationId, {
      id: 'rentalStationId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="70px"
          whiteSpace="nowrap"
        >
          Rental Stations
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            marginLeft="10px"
            fontSize="sm"
            fontWeight="600"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalSlot, {
      id: 'rentalSlot',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="70px"
          whiteSpace="nowrap"
        >
          Rental Slots
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            marginLeft="20px"
            fontSize="sm"
            fontWeight="600"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalStatus, {
      id: 'rentalStatus',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
          marginLeft={'20px'}
        >
          Rental Status
        </Text>
      ),
      cell: (info) => {
        const statusValue = info.getValue();
        const statusText = rentalStatusMap[statusValue] || 'N/A';

        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="600"
              marginLeft={'25px'}
              width={`max-content`}
            >
              {statusText}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor((row) => row?.actions, {
      id: 'actions',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="50px"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          ACTION
        </Text>
      ),
      cell: (row) => (
        <Flex align="center">
          <Link
            to={`/admin/orders/details/${row?.row?.original?.orderId}`}
            className="btn btn-primary rounded-2 p-1"
          >
            <PiEye
              size={20}
              title="View"
              className="text-white"
              style={{ marginRight: '20px' }}
            />
          </Link>
        </Flex>
      ),
    }),
  ];

  useEffect(() => {
    if (state?.merchantId) {
      setSearch(state?.merchantId);
    }
  }, [state?.merchantId]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex alignItems={'center'} flexWrap={'wrap'} mb={4} gap="20px">
        <label>Merchant Id:</label>
        <Input
          type="search"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          width="200px"
        />
        <label>Payment Status : </label>
        <Select
          value={paymentStatus}
          onChange={(e) => setPaymentStatus(e.target.value)}
          width="200px"
        >
          <option value="">All</option>
          <option value="1">Paid</option>
          <option value="0">Unpaid</option>
        </Select>

        <label>Rental Status : </label>
        <Select
          value={state?.rentalStatus ? state?.rentalStatus : rentalStatus}
          onChange={(e) => setRentalStatus(e.target.value)}
          width="200px"
        >
          <option value="">All</option>
          {Object.entries(rentalStatusMap).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </Select>

        <Button
          onClick={() => {
            getOrderList(debouncedSearch, paymentStatus, rentalStatus);
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            setPaymentStatus('');
            setRentalStatus('');
            setSearch('');
            setDebouncedSearch('');
            getOrderList('', '', '');
          }}
        >
          Reset
        </Button>
      </Flex>
      <CommonTable
        columns={columns}
        data={orderListData?.list}
        isLoading={isLoading}
        keyValue="id"
        rowclick={['actions', 'status']}
        excludes={['actions', 'status']}
      />
      {orderListData?.list?.length > 10 && (
        <CommonPagination
          totalCount={orderListData?.total}
          activePage={page}
          pageCount={Math.ceil(orderListData?.total / orderListData?.limit)}
          onPageChange={(e) => setPage(e)}
        />
      )}
    </Box>
  );
};

export default OrderList;
