import { createSlice } from '@reduxjs/toolkit';

const MerchantSlice = createSlice({
  name: 'MerchantSlice',
  initialState: {
    isLoading: false,
    isViewLoading: false,
    merchantListData: [],
    isError: null,
    viewMerchantDetails: null,
  },
  reducers: {
    /////// Merchant list start //////

    requestMerchantList: (state) => {
      return { ...state, isLoading: true };
    },
    responseMerchantList: (state, action) => {
      return {
        ...state,
        isLoading: false,
        merchantListData: action?.payload?.data,
      };
    },
    failureMerchantList: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isError: action?.payload,
      };
    },

    /////// Merchant Add-Edit //////
    requestMerchantAdd: (state) => {
      return { ...state, isLoading: true };
    },
    responseMerchantAdd: (state) => {
      return { ...state, isLoading: false };
    },
    failureMerchantAdd: (state, action) => {
      return { ...state, isLoading: false, isError: action?.payload };
    },

    ////////// Merchant View /////////
    requestMerchantView: (state) => {
      return { ...state, isViewLoading: true };
    },
    responseMerchantView: (state, action) => {
      return {
        ...state,
        isViewLoading: false,
        viewMerchantDetails: action.payload,
      };
    },
    failureMerchantView: (state, action) => {
      return { ...state, isViewLoading: false, isError: action?.payload };
    },

    ////// Merchant Delete ////
    requestMerchantDelete: (state) => {
      return { ...state, isLoading: true };
    },
    responseMerchantDelete: (state) => {
      return { ...state, isLoading: false };
    },
    failureMerchantDelete: (state, action) => {
      return { ...state, isLoading: false, isError: action?.payload };
    },
  },
});

export const {
  failureMerchantList,
  responseMerchantList,
  requestMerchantList,
  failureMerchantAdd,
  requestMerchantAdd,
  responseMerchantAdd,
  failureMerchantView,
  requestMerchantView,
  responseMerchantView,
  failureMerchantDelete,
  requestMerchantDelete,
  responseMerchantDelete,
} = MerchantSlice.actions;
export default MerchantSlice.reducer;
