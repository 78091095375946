/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ViewMerchantData } from '../../../reactRedux/thunks/merchnatThunk/merchantThunk';
import StationMerchantDetail from './StationMerchantDetail';
import PageLoader from 'components/wholePageLoader/PageLoader';
import MapView from 'components/detailMapData/MapView';
import { ListOrderData } from 'reactRedux/thunks/orderThunk/orderThunk';
import ActivatedOrderListTable from './ActivatedOrderListTable';

const MerchantDetail = () => {
  const { merchantDetailId } = useParams();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { viewMerchantDetails, isViewLoading } = useSelector(
    (state) => state.MerchantSlice,
  );
  const { orderListData, isLoading } = useSelector((state) => state.OrderSlice);

  useEffect(() => {
    if (merchantDetailId) {
      dispatch(ViewMerchantData({ id: merchantDetailId }));
    }
  }, [merchantDetailId, dispatch]);

  const getOrderList = useCallback(() => {
    dispatch(
      ListOrderData({
        page: page,
        limit: 10,
        merchantId: merchantDetailId,
        rentalStatus: 1,
      }),
    );
  }, [dispatch, page, merchantDetailId]);

  useEffect(() => {
    getOrderList();
  }, [getOrderList]);

  return (
    <>
      {isViewLoading ? (
        <PageLoader />
      ) : viewMerchantDetails ? (
        <>
          <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Flex direction="column">
              <Card
                w="100%"
                boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
                borderRadius="20px"
                paddingInline="40px"
                paddingBlock="40px"
                overflowX={{ sm: 'scroll', lg: 'hidden' }}
              >
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="24px">
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '2px' }}
                    borderRight={{ base: 'none', lg: '1px solid #ccc' }}
                  >
                    <Text fontWeight="normal">Merchant Name :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.name}
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '2px' }}
                    borderRight={{ base: 'none', lg: '1px solid #ccc' }}
                  >
                    <Text fontWeight="normal">Location Id :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.locationId}
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '2px' }}
                  >
                    <Text fontWeight="normal">Price :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.price}
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '2px' }}
                    borderRight={{ base: 'none', lg: '1px solid #ccc' }}
                  >
                    <Text fontWeight="normal">Days Limit :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.daysLimit}
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '2px' }}
                    borderRight={{ base: 'none', lg: '1px solid #ccc' }}
                  >
                    <Text fontWeight="normal">Deposit :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.deposit}
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '2px' }}
                  >
                    <Text fontWeight="normal">Currency :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.currency}
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '2px' }}
                    borderRight={{ base: 'none', lg: '1px solid #ccc' }}
                  >
                    <Text fontWeight="normal">Price Minute : </Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.priceMinute}
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '2px' }}
                    borderRight={{ base: 'none', lg: '1px solid #ccc' }}
                  >
                    <Text fontWeight="normal">Free Duration :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.freeDuration}
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="10px"
                    paddingBlock={{ base: '0', md: '2px' }}
                  >
                    <Text fontWeight="normal">Over Time Fees :</Text>
                    <Text fontWeight="semibold">
                      {viewMerchantDetails?.overtimeFees}
                    </Text>
                  </Flex>

                  <GridItem colSpan={{ base: 1, lg: 2 }}>
                    <Flex
                      align="center"
                      gap="10px"
                      paddingBlock={{ base: '0', md: '10px' }}
                    >
                      <Text fontWeight="normal">Address :</Text>
                      <Text fontWeight="semibold">
                        {viewMerchantDetails?.address}
                      </Text>
                    </Flex>
                  </GridItem>
                </SimpleGrid>

                <GridItem colSpan={{ base: 1, lg: 2 }}>
                  {/* <Text fontWeight="normal" mb={2}>
                    Coordinates :
                  </Text> */}
                  <MapView
                    coordinates={
                      viewMerchantDetails?.coordinates || [23.01052, 72.51129]
                    }
                    isChange={false}
                  />
                </GridItem>
              </Card>

              {viewMerchantDetails?.stations?.length > 0 && (
                <>
                  <Box pt="30px" alignItems="left">
                    <Text fontSize="2xl" fontWeight="medium">
                      Allocated Stations
                    </Text>
                  </Box>
                  <Card
                    w="100%"
                    boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
                    borderRadius="20px"
                    paddingInline="40px"
                    paddingBlock="40px"
                    overflowX={{ sm: 'scroll', lg: 'hidden' }}
                    mt="20px"
                  >
                    <StationMerchantDetail
                      tableData={viewMerchantDetails?.stations}
                      isLoading={isViewLoading}
                    />
                  </Card>
                </>
              )}

              <>
                <Box pt="30px">
                  <Flex justify="space-between" align="center">
                    <Text fontSize="2xl" fontWeight="medium">
                      Activated Orders
                    </Text>
                    <Button
                      onClick={() =>
                        navigate('/admin/orders', {
                          state: {
                            merchantId: merchantDetailId,
                          },
                        })
                      }
                    >
                      View All
                    </Button>
                  </Flex>
                </Box>
                <Card
                  w="100%"
                  boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
                  borderRadius="20px"
                  paddingInline="40px"
                  paddingBlock="40px"
                  overflowX={{ sm: 'scroll', lg: 'hidden' }}
                  mt="20px"
                >
                  <ActivatedOrderListTable
                    tableData={orderListData?.list}
                    isLoading={isLoading}
                  />
                </Card>
              </>
            </Flex>
          </Box>
        </>
      ) : (
        <Text>No merchant details found.</Text>
      )}
    </>
  );
};

export default MerchantDetail;
