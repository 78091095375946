import React, { useCallback } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Icon,
  IconButton,
  Input,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import CommonInput from 'components/commonInput/CommonInput';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useColorModeVariables } from '../../../components/colorModeVarialbels/ColorModeValues';
import CommonSubmitBtn from '../../../components/commonInput/CommonSubmitBtn';
import {
  AddStationData,
  EditStationData,
  ViewStationData,
} from '../../../reactRedux/thunks/stationThunk/stationThunk';
import { stationAddEditValidation } from '../../../utils/CommonValidation';
import MerchantListModal from './MerchantListModal';
import { responseStationView } from 'reactRedux/slices/stationSlice/stationSlice';
import MapView from 'components/detailMapData/MapView';

const StationAddEdit = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { textColor, brandStars } = useColorModeVariables();
  const { editStationId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedMerchants, setSelectedMerchants] = useState('');
  const [selectedMerchantName, setSelectedMerchantName] = useState('');
  const [isRemove, setIsRemove] = useState(false);
  const { isLoading, viewStationDetails } = useSelector(
    (state) => state.StationSlice,
  );
  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(stationAddEditValidation),
  });

  const onSubmit = (data) => {
    const { stationId, ...restData } = data;
    const transformedData = {
      ...restData,
      merchantId: selectedMerchants,
    };

    if (editStationId) {
      dispatch(
        EditStationData(editStationId, transformedData, () => {
          navigate('/admin/stations');
        }),
      );
    } else {
      // Exclude coordinates when adding a new station
      const addStationData = { ...transformedData, stationId };
      delete addStationData.coordinates; // Ensure coordinates is not present
      dispatch(
        AddStationData(addStationData, () => {
          navigate('/admin/stations');
        }),
      );
    }
  };

  useEffect(() => {
    if (editStationId) {
      dispatch(ViewStationData({ id: editStationId }));
    }
  }, [dispatch, editStationId]);

  useEffect(() => {
    if (viewStationDetails && editStationId) {
      setValue('stationId', viewStationDetails?.stationId);
      setValue('slots', viewStationDetails?.slots);
      setSelectedMerchants(viewStationDetails?.merchant?.merchantId || '');
      setSelectedMerchantName(viewStationDetails?.merchant?.name || '');
    }
  }, [viewStationDetails, editStationId, setValue]);

  const handleRemoveMerchant = () => {
    setSelectedMerchants('');
    setIsRemove(true);
  };

  const resetViewStationDetails = useCallback(() => {
    dispatch(responseStationView({ viewStationDetails: null }));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      resetViewStationDetails();
    };
  }, [resetViewStationDetails]);

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex direction="column" align="center" justify="center">
          <Card
            w="100%"
            boxShadow="14px 17px 40px 4px rgba(112, 144, 176, 0.08)"
            borderRadius="20px"
            paddingInline="40px"
            paddingBlock="50px"
            overflowX={{ sm: 'scroll', lg: 'hidden' }}
          >
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <FormControl>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing="24px">
                  {/* station ID */}
                  <CommonInput
                    type="text"
                    name="stationId"
                    labelText="Station Id"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="Station Id"
                    register={register('stationId')}
                    errors={errors?.stationId}
                  />

                  {/* terminal ID */}
                  <CommonInput
                    type="number"
                    name="slots"
                    labelText="Slots"
                    textColor={textColor}
                    brandStars={brandStars}
                    placeholder="Slots"
                    register={register('slots')}
                    errors={errors?.slots}
                  />

                  <GridItem colSpan={{ base: 1, lg: 2 }}>
                    {editStationId &&
                      viewStationDetails?.merchant?.merchantId && (
                        <MapView
                          coordinates={
                            viewStationDetails?.coordinates || [0, 0]
                          }
                          isChange={false}
                        />
                      )}
                  </GridItem>
                </SimpleGrid>

                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="24px">
                  {!selectedMerchants ? (
                    <Button
                      type="button"
                      bg="reviveOrange"
                      mt="50px"
                      h="50"
                      color="primary"
                      borderRadius="12px"
                      _hover={{ bg: 'reviveOrange', opacity: 0.8 }}
                      onClick={onOpen}
                    >
                      <Icon
                        as={FaPlus}
                        width="15px"
                        height="15px"
                        borderRadius="12px"
                        marginRight="5px"
                        color="inherit"
                      />
                      Add Merchant
                    </Button>
                  ) : (
                    <Flex align="center" mt={2} marginTop={'50px'}>
                      <FormLabel flex={'none'} color={textColor}>
                        Merchant
                      </FormLabel>
                      <Input
                        value={
                          selectedMerchantName
                            ? selectedMerchantName
                            : selectedMerchants
                        }
                        readOnly
                        variant="auth"
                        fontSize="sm"
                        mb="2px"
                        fontWeight="500"
                        size="lg"
                      />
                      <IconButton
                        aria-label="Remove Merchant"
                        icon={<CloseIcon />}
                        size="sm"
                        ms={3}
                        onClick={handleRemoveMerchant}
                      />
                    </Flex>
                  )}
                </SimpleGrid>

                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="24px">
                  <CommonSubmitBtn BTNtext="Submit" isLoading={isLoading} />
                </SimpleGrid>
                <MerchantListModal
                  isOpen={isOpen}
                  onClose={onClose}
                  setSelectedMerchants={setSelectedMerchants}
                  setIsRemove={setIsRemove}
                  isRemove={isRemove}
                  setSelectedMerchantName={setSelectedMerchantName}
                />
              </FormControl>
            </form>
          </Card>
        </Flex>
      </Box>
    </>
  );
};

export default StationAddEdit;
