import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';

const MapView = ({ coordinates, isChange = false, setCoordinates }) => {
  const defaultCoordinates = [23.0117639, 72.5095528];
  const [lng, lat] = coordinates || defaultCoordinates;

  const center = { lat, lng };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ height: '400px', width: '100%' }}
      center={center}
      zoom={15}
      onClick={(event) => {
        if (isChange) {
          setCoordinates({
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          });
        }
      }}
    >
      <Marker position={center} />
    </GoogleMap>
  ) : (
    <></>
  );
};

MapView.propTypes = {
  coordinates: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }).isRequired,
  isChange: PropTypes.bool.isRequired,
  setCoordinates: PropTypes.func,
};

export default MapView;
