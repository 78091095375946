import * as Yup from 'yup';

export const signInValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  password: Yup.string()
    // .min(8, "Password must be at least 8 characters")
    .required('Password is required'),
});

export const userAddEditValidation = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  // email: Yup.string()
  //   .email("Invalid email format")
  //   .required("Email is required"),
  phone: Yup.string()
    .required('Phone number is required')
    .max(12, 'Phone number should be less than 12 digits'),
});

export const merchantAddEditValidation = Yup.object().shape({
  // merchantId: Yup.string().required('Merchant Id is required'),
  name: Yup.string().required('Merchant Name is required'),
  freeDuration: Yup.number()
    .transform((value) => (value === '' || isNaN(value) ? undefined : value))
    .required('Free Duration is required')
    .positive('Must be a positive number'),
  price: Yup.number()
    .transform((value) => (value === '' || isNaN(value) ? undefined : value))
    .required('Price is required')
    .positive('Must be a positive number'),
  deposit: Yup.number()
    .transform((value) => (value === '' || isNaN(value) ? undefined : value))
    .required('Deposit is required')
    .positive('Must be a positive number'),
  daysLimit: Yup.number()
    .transform((value) => (value === '' || isNaN(value) ? undefined : value))
    .required('Days Limit is required')
    .positive('Must be a positive number'),
});

export const stationAddEditValidation = Yup.object().shape({
  stationId: Yup.string().required('Station Id is required'),
  slots: Yup.number()
    .typeError('Slots must be a number')
    .required('Slots is required')
    .positive('Slots must be a positive number')
    .integer('Slots must be an integer')
    .min(1, 'Slots must be at least 1')
    .max(100, 'Slots cannot exceed 100'),
  // terminalId: Yup.string().required('Terminal Id is required'),
  // coordinates: Yup.array()
  //     .of(Yup.object().shape({
  //         value: Yup.string().required('Coordinate is required')
  //     }))
  //     .min(1, 'At least one coordinate is required'),
});

export const powerbankIdAddEditValidation = Yup.object().shape({
  powerbankId: Yup.string().required('Powerbank Id is required'),
  // stationId: Yup.string().required('Station Id is required'),
});
