import React from 'react';
import { Flex } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import customLogo from '../../../assets/img/layout/Revive-logo.svg';

export function SidebarBrand() {
  return (
    <Flex align="center" direction="column">
      <img
        src={customLogo}
        alt="Revive Logo"
        style={{ height: '26px', width: '175px', marginBlock: '0px 30px' }}
      />
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
