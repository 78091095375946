import React from 'react';
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonTable from 'components/commonTable/CommonTable';
import moment from 'moment';
import { rentalStatusMap } from 'utils/StaticData';
import PropTypes from 'prop-types';

const ActivatedOrderListTable = ({ tableData, isLoading }) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.orderId, {
      id: 'orderId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="40px"
          whiteSpace="nowrap"
        >
          Order Id
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            marginLeft="10px"
            fontSize="sm"
            fontWeight="600"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.powerbankId, {
      id: 'powerbankId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
        >
          Powerbank
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.paymentType, {
      id: 'paymentType',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
        >
          Payment Type
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.paymentStatus, {
      id: 'paymentStatus',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="70px"
          whiteSpace="nowrap"
          marginLeft={'10px'}
        >
          Payment Status
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={info.getValue() === 1 ? 'green.500' : 'red.500'}
            marginLeft="10px"
            fontSize="sm"
            fontWeight="600"
          >
            {info.getValue() === 1 ? 'Paid' : 'Unpaid'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.duration, {
      id: 'duration',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
          marginLeft={'10px'}
        >
          Duration
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            marginLeft="10px"
            fontSize="sm"
            fontWeight="600"
            width={'max-content'}
          >
            {info.getValue() ? info.getValue() : 'N/A'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalMerchantId, {
      id: 'rentalMerchantId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
          margin={'10px'}
        >
          Rental Merchant
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalMerchant, {
      id: 'rentalMerchant',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
        >
          Rental Merchant
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="600"
            width={`max-content`}
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalStationId, {
      id: 'rentalStationId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="70px"
          whiteSpace="nowrap"
        >
          Rental Stations
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            marginLeft="10px"
            fontSize="sm"
            fontWeight="600"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.rentalSlot, {
      id: 'rentalSlot',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="70px"
          whiteSpace="nowrap"
        >
          Rental Slots
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            marginLeft="20px"
            fontSize="sm"
            fontWeight="600"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.rentTime, {
      id: 'rentTime',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
        >
          Rent Time
        </Text>
      ),
      cell: (info) => {
        const rentTime = info.getValue();
        const formattedDateTime = moment(rentTime).format(
          'YYYY-MM-DD HH:mm:ss',
        );
        return (
          <Flex align="center">
            <Text
              color={textColor}
              marginLeft="3px"
              fontSize="sm"
              fontWeight="600"
              width={`max-content`}
            >
              {formattedDateTime}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor((row) => row?.rentalStatus, {
      id: 'rentalStatus',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
          whiteSpace="nowrap"
          marginLeft={'20px'}
        >
          Rental Status
        </Text>
      ),
      cell: (info) => {
        const statusValue = info.getValue();
        const statusText = rentalStatusMap[statusValue] || 'N/A';

        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="600"
              marginLeft={'25px'}
              width={`max-content`}
            >
              {statusText}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor((row) => row?.settlementAmount, {
      id: 'settlementAmount',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="70px"
          whiteSpace="nowrap"
        >
          Settlement Amount
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            marginLeft="20px"
            fontSize="sm"
            fontWeight="600"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
  ];

  return (
    <>
      <CommonTable
        columns={columns}
        data={tableData}
        isLoading={isLoading}
        keyValue="id"
        rowclick={['actions', 'status']}
        excludes={['actions', 'status']}
      />
      {/* {tableData?.length > 10 && (
              <CommonPagination
                totalCount={orderListData?.total}
                activePage={page}
                pageCount={Math.ceil(orderListData?.total / orderListData?.limit)}
                onPageChange={(e) => setPage(e)}
              />
            )} */}
    </>
  );
};

ActivatedOrderListTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ActivatedOrderListTable;
