import {
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CommonPagination from 'components/commonPagination/CommonPagination';
import CommonTable from 'components/commonTable/CommonTable';
import DeleteModal from 'components/deleteModalPopUp/DeleteModal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListStationData } from 'reactRedux/thunks/stationThunk/stationThunk';
import { PiPencil, PiEye, PiTrash } from 'react-icons/pi';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteStationData } from 'reactRedux/thunks/stationThunk/stationThunk';
import { FaPlus } from 'react-icons/fa';
import { Icon } from '@chakra-ui/react';

const StationList = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const stationDeleteRef = useRef(null);
  const navigate = useNavigate();
  // const [search, setSearch] = useState("")
  const { isLoading, stationListData } = useSelector(
    (state) => state.StationSlice,
  );

  const getStationList = useCallback(() => {
    dispatch(
      ListStationData({
        page: page,
        limit: 10,
        // search: search
      }),
    );
  }, [dispatch, page]);

  useEffect(() => {
    getStationList();
  }, [getStationList]);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row) => row?.stationId, {
      id: 'stationId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="90px"
        >
          Station Id
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.slots, {
      id: 'slots',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="50px"
        >
          Slots
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            padding={'8px'}
            fontSize="sm"
            fontWeight="600"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.merchantName, {
      id: 'merchantName',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="80px"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          Merchant
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="600">
            {info.getValue() ? info.getValue() : 'N/A'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor((row) => row?.actions, {
      id: 'actions',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          width="50px"
          fontFamily="Montserrat"
          whiteSpace="nowrap"
        >
          ACTION
        </Text>
      ),
      cell: (row) => (
        <Flex align="center">
          <Link
            to={`/admin/stations/edit/${row?.row?.original?.stationId}`}
            className="btn btn-light rounded-3 p-1"
          >
            <PiPencil size={20} title="Edit" style={{ marginRight: '20px' }} />
          </Link>
          <Link
            to={`/admin/stations/details/${row?.row?.original?.stationId}`}
            className="btn btn-primary rounded-2 p-1"
          >
            <PiEye
              size={20}
              title="View"
              className="text-white"
              style={{ marginRight: '20px' }}
            />
          </Link>
          <div className="btn btn-danger rounded-3 p-1">
            <PiTrash
              size={20}
              className="text-white"
              onClick={() => {
                // setShow(true);
                onOpen();
                stationDeleteRef.current = row?.row?.original?.stationId;
              }}
              title="Delete"
            />
          </div>
        </Flex>
      ),
    }),
  ];

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex justify="flex-end" mb={4} gap="20px">
          {/* <CommonSearch search={search} setSearch={setSearch} setPage={setPage} /> */}
          <Button
            bg="reviveOrange"
            color="primary"
            borderRadius="12px"
            _hover={{ bg: 'reviveOrange', opacity: 0.8 }}
            onClick={() => navigate('/admin/stations/add')}
          >
            <Icon
              as={FaPlus}
              width="15px"
              height="15px"
              marginRight="5px"
              color="inherit"
            />
            Add Station
          </Button>
        </Flex>
        <CommonTable
          columns={columns}
          data={stationListData?.list}
          isLoading={isLoading}
          keyValue="id"
          rowclick={['actions', 'status']}
          excludes={['actions', 'status']}
        />
        {stationListData?.total > 10 && (
          <CommonPagination
            totalCount={stationListData?.total}
            activePage={page}
            pageCount={Math.ceil(
              stationListData?.total / stationListData?.limit,
            )}
            onPageChange={(e) => setPage(e)}
          />
        )}

        <DeleteModal
          isOpen={isOpen}
          onClose={onClose}
          title="Are you sure you want to delete this station?"
          SolidBTNText="Delete"
          onDelete={() => {
            dispatch(
              DeleteStationData(stationDeleteRef.current, {}, () => {
                getStationList();
                onClose();
              }),
            );
          }}
          loader={isLoading}
        />
      </Box>
    </>
  );
};

export default StationList;
